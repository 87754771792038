<template>
	<div :lang="$i18n.locale" v-loading.fullscreen="uploadloading" element-loading-text="" element-loading-spinner="el-icon-loading"
		element-loading-background="rgba(0, 0, 0, 0.8)" class="app-container">
		<el-tabs v-model="activeName" type="card" @tab-click="handleClick">
			<!-- 整箱 -->
			<el-tab-pane :label="$t('ksaWhInventoryPage.tabThreeTitle')" name="first">
				<div class="listHeader">
					<i></i><span>{{$t('ksaWhInscanLogs.pageCartonTitle2')}}</span>
				</div>
				<el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
					style="margin:10px 0 0 20px">
					<el-form-item label>
						<el-input type="textarea" v-model="tobOrderNo" :placeholder="$t('commonInfo.orderNoColumn')"
							clearable />
					</el-form-item>
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="handleSearch">
							{{ $t('commonInfo.searchBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="refresh">{{$t('commonInfo.dialogBtnReset')}}
						</el-button>
					</el-form-item>
				</el-form>

				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="loading"
					:data="dataList" border>
					<el-table-column prop="orderNo" :label="$t('commonInfo.orderNoColumn')" align="center">
					</el-table-column>
					<el-table-column prop="cartonNo" :label="$t('commonInfo.packageNo')" align="center">
					</el-table-column>
					<el-table-column prop="transportType" :label="$t('commonInfo.transportType')" align="center">
						<template slot-scope="{ row }">
							<span>{{returnActionStatus(row,1)}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="initTime" :label="$t('ksaWhInboundLogs.inboundTime')" align="center">
					</el-table-column>
				</el-table>

				<div class="pageBottom">
					<el-pagination @size-change="handleImportSizeChange" @current-change="handleImportCurrentChange"
						:current-page="queryParams.page" :page-sizes="[10, 20, 30, 50]" :page-size="queryParams.limit"
						layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
				</div>
			</el-tab-pane>
			<el-tab-pane label="Sku" name="second">
				<div class="listHeader">
					<i></i><span>{{$t('ksaWhInboundLogs.tabOneTitle2')}}</span>
				</div>
				<el-form :model="querySkuParams" ref="queryForm" :inline="true" label-width="auto"
					style="margin:10px 0 0 20px">
					<el-form-item label>
						<el-input type="text" v-model="querySkuParams.clientProductCode" :placeholder="$t('commonInfo.clientProductCode')"
							clearable />
					</el-form-item>
					<el-form-item label >
						<el-date-picker v-model="operationTime" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
							range-separator="-" :start-placeholder="$t('commonInfo.beginTime')" :end-placeholder="$t('commonInfo.endTime')" style="margin-right: 10px"
							></el-date-picker>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" icon="el-icon-search" @click="handleSearchSku">
							{{ $t('commonInfo.searchBtn')}}</el-button>
						<el-button type="primary" icon="el-icon-refresh" @click="handleRefreshSku">
							{{ $t('commonInfo.refreshBtn')}}</el-button>
						<el-button icon="el-icon-refresh" @click="handleResetSku">{{$t('commonInfo.dialogBtnReset')}}
						</el-button>
					</el-form-item>
				</el-form>
				
				<!-- 展示 -->
				<el-table style="margin:0 15px;width: calc(100% - 30px);font-size: 16px;" v-loading="skuTableLoading"
					:data="skuDataList" border>
					<el-table-column prop="productNm" :label="$t('commonInfo.productName')" align="center">
					</el-table-column>
					<el-table-column prop="commodityId" :label="$t('commonInfo.productCode')" align="center">
					</el-table-column>
					<el-table-column prop="clientProductCode" :label="$t('commonInfo.clientProductCode')" align="center">
					</el-table-column>
					<el-table-column prop="count" :label="$t('commonInfo.count')" align="center">
					</el-table-column>
					<el-table-column prop="createTime" :label="$t('ksaWhInboundLogs.inboundTime')" align="center">
					</el-table-column>
				</el-table>
				
				<div class="pageBottom">
					<el-pagination @size-change="handleSkuSizeChange" @current-change="handleSkuCurrentChange"
						:current-page="querySkuParams.page" :page-sizes="[10, 20, 30, 50]" :page-size="querySkuParams.limit"
						layout="total, sizes, prev, pager, next, jumper" :total="skuTotal"></el-pagination>
				</div>
      </el-tab-pane>
		</el-tabs>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeName: 'first',
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now() + 24 * 60 * 60 * 1000; //可选范围在下一天
					}
				},
				dataList: [], //列表
				value1: '', //时间
				loading: false,
				uploadloading: false,
				tobOrderNo: '', //订单号
				total: 0,
				queryParams: {
					inOrOutFlag: 0,//上架
					tobOrderNoList: [],
					page: 1,
					limit: 10,
				},
				productCodeString: '',
				
				// sku
				skuDataList: [], //列表
				skuTableLoading: false,
				skuTotal: 0,
				operationTime:'',
				querySkuParams: {
					inOrOutFlag: 0,//上架
					clientProductCode: '',
					instockDateStart:'',
					instockDateEnd:'',
					page: 1,
					limit: 10,
				},
			}
		},
		computed: {

		},
		methods: {
			handleClick(tab, event) {
				if (tab.name == "first") {
					this.getList();
				} else if (tab.name == "second") {
					this.getSkuList();
				}
			},
			//列表
			async getList() {
				this.loading = true;
				const res = await this.$http.post("/stockLog/carton", this.queryParams);

				if (res.code == 200) {
					this.dataList = res.data.list;
					this.total = res.data.totalCount;
					this.loading = false;
				} else {
					this.loading = false;
					this.$message.error(res.msg);
				}
			},
			//搜索
			handleSearch() {
				this.queryParams.page = 1;
				this.queryParams.limit = 10;
				if (this.tobOrderNo != '') {
					this.queryParams.tobOrderNoList = this.strToArr(this.tobOrderNo);
				} else {
					this.queryParams.tobOrderNoList = [];
				}
				this.getList();
			},
			//重置
			refresh() {
				this.queryParams = {
					inOrOutFlag: 0,
					tobOrderNoList: [],
					page: 1,
					limit: 10,
				}
				this.tobOrderNo = '';
				this.getList();
			},
			//翻译状态
			returnActionStatus(row, type) {
				let statusName = ''
				switch (row.transportType) {
					case '100':
						statusName = this.$t('commonInfo.air');
						break;
					case '200':
						statusName = this.$t('commonInfo.sea');
						break;
					case '300':
						statusName = this.$t('commonInfo.local');
						break;
				}

				if (type == 1) {
					return statusName
				} else {

				}
			},
			// 分页条数
			handleImportSizeChange(size) {
				this.queryParams.limit = size;
				this.queryParams.page = 1;
				this.getList();
			},
			// 分页页数
			handleImportCurrentChange(page) {
				this.queryParams.page = page;
				this.getList();
			},
			
			
			// SKU 
			//列表
			async getSkuList() {
				this.skuTableLoading = true;
				const res = await this.$http.post("/stockLog/sku", this.querySkuParams);
				if (res.code == 200) {
					this.skuDataList = res.data.list;
					this.skuTotal = res.data.totalCount;
					this.skuTableLoading = false;
				} else {
					this.skuTableLoading = false;
					this.$message.error(res.msg);
				}
			},
			//搜索
			handleSearchSku() {
				this.querySkuParams.page = 1;
				if(this.operationTime != '' && this.operationTime != null){
					this.querySkuParams.instockDateStart = this.operationTime[0];
					this.querySkuParams.instockDateEnd = this.operationTime[1];
				}else{
					this.querySkuParams.instockDateStart = '';
					this.querySkuParams.instockDateEnd = '';
				}
				this.getSkuList();
			},
			// 刷新
			handleRefreshSku(){
				this.getSkuList();
			},
			//重置
			handleResetSku() {
				this.querySkuParams = {
					inOrOutFlag: 0,//上架
					clientProductCode: '',
					instockDateStart:'',
					instockDateEnd:'',
					page: 1,
					limit: 10,
				}
				this.operationTime = '';
				this.getSkuList();
			},
			// 分页条数
			handleSkuSizeChange(size) {
				this.querySkuParams.limit = size;
				this.querySkuParams.page = 1;
				this.getSkuList();
			},
			// 分页页数
			handleSkuCurrentChange(page) {
				this.querySkuParams.page = page;
				this.getSkuList();
			},
		},
		created() {
			this.getList();
		},
		watch: {

		}
	}
</script>

<style scoped>
	.app-container {
		padding: 10px;
	}

	.listHeader {
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
	}

	.listHeader i {
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}

	.listHeader span {
		display: inline-block;
		float: left;
	}

	.pageBottom {
		padding: 20px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
	}
</style>
